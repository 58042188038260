import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import Flickity from 'react-flickity-component'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'

const flickityHero = {
    prevNextButtons: true,
    pageDots: false,
    cellSelector: '.slider__hero-slider-cell',
    wrapAround: true,
    setGallerySize: true,
    cellAlign: 'center',
    autoPlay: 5000,
}

function SliderHero(p) {
    return (
        <Flickity
        className={'slider__hero'}
        elementType={'div'}
        options={flickityHero}
        disableImagesLoaded={false}
        reloadOnUpdate={true}
        static={true}
        >
        {p.p.map((slide, i) => {
            return (
            <div key={i} className="slider__hero-slider-cell">
                <BackgroundImage style={{ height: "400px" }} fluid={slide.image.childImageSharp.fluid}>
                    <div className="hero__gradient hero__relative">
                        <div className="hero__text center">
                            <h2>{slide.text}</h2>
                            <p>Phone us for a quick response and fast quote.<br />Call us now on <a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a>.</p>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            )
        })}
        </Flickity>
    )
}

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&family=Varela&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <SliderHero p={entry.frontmatter.slides} />
            </div>
            <div className="layout body blue">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__3 grid__gap--48 why">
                            <div>
                                <img src='/img/17_client_service.svg' alt='Same Day Service' />
                                <h3>Same Day Service</h3>
                                <p>Our services are available on the same day service.</p>
                            </div>
                            <div>
                                <img src='/img/10_road_route.svg' alt='Auckland-wide Service' />
                                <h3>Auckland-wide Service</h3>
                                <p>We operate throughout the entire Auckland region.</p>
                            </div>
                            <div>
                                <img src='/img/01_financial_data.svg' alt='On-site Quotes' />
                                <h3>On-site Quotes</h3>
                                <p>We invoice on-site so you get all the facts to make an informed decision.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--72 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <Link to="/contact" title="Contact" className="btn btn__large btn__margin">Request quote &nbsp;►</Link>
                            </div>
                            <div className="aside">
                                <Img fluid={entry.frontmatter.side_image.childImageSharp.fluid} alt={entry.frontmatter.side_heading} />
                                <h3>{entry.frontmatter.side_heading}</h3>
                                <ul>
                                    {entry.frontmatter.side_text.map( (entry, i) => (
                                        <li key={i}>{entry.text}</li>
                                    ))}
                                </ul>
                                <figure className="partners">
                                    <a href="https://www.topreviews.co.nz/best-garage-door-repair-auckland/#7_Eden_Garage_Doors" title="Top Reviews" rel="nofollow noopener"><Img fixed={data.top_reviews.childImageSharp.fixed} alt='Top Reviews' /></a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        top_reviews: file(relativePath: { eq: "top_reviews.jpg" }) {
            childImageSharp {
                fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                slides {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1280) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    text
                }
                side_heading
                side_text {
                    text
                }
                side_image {
                    childImageSharp {
                        fluid(maxWidth: 640, maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`